<template>
  <div class="d-flex flex-column min-vh-100 bg-transition" :class="bgClass">

    <b-modal id="modal-intro" hide-footer hide-header centered>
      <div class="text-center">
        <h2 class="mb-4">Ready... set... </h2>
        <p>Select one of the two answers on each page to find your campus match.</p>
        <div class="d-block mt-3">
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary btn-lg" v-on:click="$bvModal.hide('modal-intro')">Go!</button>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="mt-auto mb-auto quiz-wrapper">

      <transition-group name="form-item" tag="div">
        <div class="row wrapper-max-1000 quiz-step" v-if="step !== -1 && step !== 1000" key="stepQuestion" v-bind:class="{'quiz-step-enter': quizStepAnimEnter, 'quiz-step-leave': quizStepAnimLeave}">
          <div class="col-12 mb-2 text-center">
            <span class="quiz-counter">{{step + 1}} of 9</span>
          </div>
          <div class="col-12 col-lg-5 text-center text-lg-right">
            <button :disabled="stepSwitchProgress" class="btn btn-quiz" v-bind:class="{'btn-quiz-active': btnAnswerOne}" v-on:click="btnAnswerOne = true, gotoStep(quizContent[0], 0)">{{quizContent[0].text}}</button>
          </div>
          <div class="col-12 col-lg-2 mt-2 mb-2 text-center">
            <span class="display-1 pulsate quiz-or">or</span>
          </div>
          <div class="col-12 col-lg-5 mb-5 text-center text-lg-left">
            <button :disabled="stepSwitchProgress" class="btn btn-quiz btn-quiz-2" v-bind:class="{'btn-quiz-active': btnAnswerTwo}" v-on:click="btnAnswerTwo = true, gotoStep(quizContent[1], 1)">{{quizContent[1].text}}</button>
          </div>
        </div>

        <div class="row wrapper-max-760 quiz-step" v-if="step === 1000" key="stepOutro" v-bind:class="{'quiz-step-enter': quizStepAnimEnter, 'quiz-step-leave': quizStepAnimLeave}">
          <div class="col-12 text-center">
            <h2 class="display-2">Your perfect match is...</h2>
            <h1 class="h-mega quiz-result">{{result.title}}</h1>
            <p class="quiz-intro">{{result.appText}}</p>
            <p class="quiz-intro mb-4">Wherever you choose to start your program, Global Rotation can take you further. Explore all of Hult’s campus locations and rotation centers at <a class="link" target="_blank" href="https://www.hult.edu/rotation">hult.edu/rotation</a></p>
            <button class="btn btn-primary btn-lg mb-5" v-on:click="shareResult()">Share your result</button>
          </div>
        </div>

      </transition-group>

    </div>

    <div class="quiz-images">
      <article class="quiz-image" v-if="step !== -1 && step !== 1000" key="images" v-bind:class="{'quiz-image-enter': quizStepAnimEnter, 'quiz-image-leave-top': quizStepChoiceTop, 'quiz-image-leave-bottom': quizStepChoiceBottom}">
        <div class="quiz-img-top">
          <picture>
            <source :srcset="imgOneSrc.s" media="(max-width: 499px)" @load="onImgLoad">
            <source :srcset="imgOneSrc.m" media="(min-width: 499px)" @load="onImgLoad">
            <source :srcset="imgOneSrc.l" media="(min-width: 1200px)" @load="onImgLoad">
            <img :srcset="imgOneSrc.src" alt="" @load="onImgLoad">
          </picture>
        </div>
        <div class="quiz-img-bottom">
          <picture>
            <source :srcset="imgTwoSrc.s" media="(max-width: 499px)" @load="onImgLoad">
            <source :srcset="imgTwoSrc.m" media="(min-width: 499px)" @load="onImgLoad">
            <source :srcset="imgTwoSrc.l" media="(min-width: 1200px)" @load="onImgLoad">
            <img :srcset="imgTwoSrc.src" alt="" @load="onImgLoad">
          </picture>
        </div>
      </article>
    </div>

  </div>
</template>

<script>
import { personalityQuiz, personalityResults } from '@/data/personalityQuiz'
import { EventBus } from "@/event-bus.js"; 

export default {
  name: 'PersonalityQuiz',
  data() {
    return {
      btnAnswerOne: false,
      btnAnswerTwo: false,
      stepSwitchProgress: false,
      step: -1,
      stream: 0,
      questionInStream: 0,
      quizStepAnimEnter: false,
      quizStepAnimLeave: false,
      quizContent: [],
      answerCodes: [],
      result: null,
      quizStepChoiceTop: false,
      quizStepChoiceBottom: false,
      timeoutVal: 0,
      imgLoadCount: 0
    }
  },
  computed: {
    bgClass: function () {
      var bg = 'bg-none';
      return bg;
    },
    imgOneSrc: function () {
      var img = {
        src: '',
        s: '',
        m: '',
        l: ''
      }
      if (this.quizContent[0] !== undefined) {
        img = {
          src: "/img/quiz/small/" + this.quizContent[0].img,
          s: "/img/quiz/small/" + this.quizContent[0].img,
          m: "/img/quiz/medium/" + this.quizContent[0].img,
          l: "/img/quiz/large/" + this.quizContent[0].img,
        }
      }
      return img
    },
    imgTwoSrc: function () {
      var img = {
        src: '',
        srcset: ''
      }
      if (this.quizContent[1] !== undefined) {
        img = {
          src: "/img/quiz/small/" + this.quizContent[1].img,
          s: "/img/quiz/small/" + this.quizContent[1].img,
          m: "/img/quiz/medium/" + this.quizContent[1].img,
          l: "/img/quiz/large/" + this.quizContent[1].img,
        }
      }
      return img
    }
  },
  methods: {
    onImgLoad: function () {
      var self = this;
      this.imgLoadCount++;
      console.log('loaded', this.imgLoadCount)

      if (this.imgLoadCount === 2) {
        this.imgLoadCount = 0;
        this.quizStepAnimEnter = true;
        setTimeout(function() {
          self.stepSwitchProgress = false;
          self.$store.state.bodyBackground = "bg-none";
        }, (800));
      }

    },
    gotoStep: function (answer, choice) {
      var self = this;
      if (!self.stepSwitchProgress) {
        self.stepSwitchProgress = true;
        // self.quizStepAnimEnter = false;
        self.quizStepAnimLeave = true;

        if (choice === 0) {
          self.quizStepChoiceTop = true
        } else if (choice === 1) {
          self.quizStepChoiceBottom = true 
        }

        setTimeout(function() {
          self.quizStepAnimEnter = false;
          self.quizStepAnimLeave = false;
          self.quizStepChoiceTop = self.quizStepChoiceBottom = false;
          self.step++;
          self.timeoutVal = 1600;
          console.log(answer);

          // Isolate intro/outro steps. Logic for questions and anwers
          if (answer !== null) {
            console.log('Stream length: ', personalityQuiz[self.stream].questions.length, ', Question index: ', self.questionInStream)
            
            // Collect answer values. Some values have multiple codes.
            if (answer.val.includes(',')) {
              var codes = answer.val.split(',')
              codes.forEach(function (code) {
                self.answerCodes.push(code)
              });
            } else {
              if (answer.val !== 'N') {
                self.answerCodes.push(answer.val) 
              }
            }
            
            // Detect end of stream. Determine the result
            if (personalityQuiz[self.stream].questions.length === (self.questionInStream + 1)) {
              var resultCode = '';
              if (self.answerCodes.includes('ONLINE')) {
                // Override rule for online
                resultCode = 'ONLINE'
              } else {
                // Find the biggest collection of codes
                resultCode = self.findWinner(self.answerCodes);
              }
              self.result = personalityResults.find(o => o.val === resultCode);

              console.log('It was the last question in stream. Display result');
              console.log(self.result);
              self.$store.state.quizResult = self.result
              self.$router.push('/quiz/result')
              return false
            }

            // Detect change of streams
            if (answer.toStream !== undefined) {
              console.log('Stream switch')
              self.stream = answer.toStream;
              self.questionInStream = 0;
            }
            else {
              self.questionInStream++;
            }
            self.quizContent = personalityQuiz[self.stream].questions[self.questionInStream]

            console.log('New data loaded', self.quizContent)
          }
          // Adjust UI
          self.btnAnswerOne = self.btnAnswerTwo = false;

        }, self.timeoutVal);

      }
    },

    findWinner: function(array) {
      if (array.length == 0)
        return null;
      var modeMap = {};
      var maxEl = array[0], maxCount = 1;
      for (var i = 0; i < array.length; i++) {
        var el = array[i];
        if (modeMap[el] == null)
          modeMap[el] = 1;
        else
          modeMap[el]++;  
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }
      return maxEl;
    },

    preload: function (arr) {
      var images = []
      for (var i = 0; i < arr.length; i++) {
        images[i] = new Image();
        images[i].src = arr[i];
      }
    },

    shareResult: function() {
      EventBus.$emit("share", this.result);
    }

  },
  mounted() {
    var self = this;
    this.$store.state.home = false;
    this.$store.state.logoHide = true;
    this.$store.state.bodyBackground = "bg-none app-scroll-lock";

    // Attach first question content
    console.log(personalityQuiz);
    this.quizContent = personalityQuiz[this.stream].questions[this.questionInStream];

    // Preload quiz images
    // var images = [];
    // personalityQuiz.forEach(function(stream) {
    //   stream.questions.forEach(function (questionSet) {
    //     questionSet.forEach(function (question) {
    //       images.push("/img/quiz/small/" + question.img);
    //     })
    //   })
    // })
    // this.preload(images)
    self.gotoStep(null, null);
    self.$bvModal.show('modal-intro');
    setTimeout(function() {
      self.quizStepAnimEnter = true;
    }, 500);
  }
}
</script>