var personalityQuiz = [
  {
    stream: 0,
    questions: [
      [
        {
          text: "Bachelors",
          val: "N",
          img: "Quiz_images__Q1-Bachelors-Stream-1-2.jpg"
        },
        {
          text: "Graduate",
          val: "N",
          img: "Quiz_images__Q1-Graduate-Stream-1-2.jpg",
          toStream: 1
        },
      ],
      [
        {
          text: "History",
          val: "N",
          img: "Quiz_images__Q2-history-Stream-1-2.jpg"
        },
        {
          text: "Modernity",
          val: "N",
          img: "Quiz_images__Q2-Modernity-Stream-1-2.jpg"
        },
      ],
      [
        {
          text: "Bright lights",
          val: "LON",
          img: "Quiz_images__Q3-Bright-lights-Stream-1-2.jpg"
        },
        {
          text: "Chilled nights",
          val: "BOS",
          img: "Quiz_images__Q3-chilled-out-night-Stream-1-2.jpg"
        },
      ],
      [
        {
          text: "Join the race",
          val: "LON",
          img: "Quiz_images__Q4-Join-the-race-Stream-1-2.jpg" 
        },
        {
          text: "Go at your own pace",
          val: "BOS",
          img: "Quiz_images__Q4-Own-pace-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Double espresso",
          val: "BOS",
          img: "Quiz_images__Q5-Espresso-1-2.jpg" 
        },
        {
          text: "Pot of chai",
          val: "LON",
          img: "Quiz_images__Q5-chai-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Team sports",
          val: "BOS",
          img: "Quiz_images__Q6-Team-sport-1-2.jpg" 
        },
        {
          text: "E-sports",
          val: "LON",
          img: "Quiz_images__Q6-Esport-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Pie & mash",
          val: "LON",
          img: "Quiz_images__Q7-Pie-and-mash-1-2.jpg" 
        },
        {
          text: "Lobster roll",
          val: "BOS",
          img: "Quiz_images__Q7-Lobster-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Hang a pennant",
          val: "BOS",
          img: "Quiz_images__Q8-Pennant-1-2.jpg" 
        },
        {
          text: "Get a tattoo",
          val: "LON",
          img: "Quiz_images__Q8-Get-Tattoo-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Follow fashion",
          val: "BOS",
          img: "Quiz_images__Q9-Follow-fastion-Stream-1-2.jpg" 
        },
        {
          text: "Set the trends",
          val: "LON",
          img: "Quiz_images__Q9-set-trends-Stream-1-2.jpg"
        }
      ],
    ]
  },
  {
    stream: 1,
    questions: [
      [
        {
          text: "History",
          val: "N",
          img: "Quiz_images__Q2-history-Stream-1-2.jpg"
        },
        {
          text: "Modernity",
          val: "N",
          img: "Quiz_images__Q2-Modernity-Stream-1-2.jpg",
          toStream: 2
        },
      ],
      [
        {
          text: "Bright lights",
          val: "LON",
          img: "Quiz_images__Q3-Bright-lights-Stream-1-2.jpg"
        },
        {
          text: "Chilled nights",
          val: "BOS",
          img: "Quiz_images__Q3-chilled-out-night-Stream-1-2.jpg"
        },
      ],
      [
        {
          text: "Join the race",
          val: "LON",
          img: "Quiz_images__Q4-Join-the-race-Stream-1-2.jpg" 
        },
        {
          text: "Go at your own pace",
          val: "BOS",
          img: "Quiz_images__Q4-Own-pace-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Double espresso",
          val: "BOS",
          img: "Quiz_images__Q5-Espresso-1-2.jpg" 
        },
        {
          text: "Pot of chai",
          val: "LON",
          img: "Quiz_images__Q5-chai-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Team sports",
          val: "BOS",
          img: "Quiz_images__Q6-Team-sport-1-2.jpg" 
        },
        {
          text: "E-sports",
          val: "LON",
          img: "Quiz_images__Q6-Esport-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Pie & mash",
          val: "LON",
          img: "Quiz_images__Q7-Pie-and-mash-1-2.jpg" 
        },
        {
          text: "Lobster roll",
          val: "BOS",
          img: "Quiz_images__Q7-Lobster-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Hang a pennant",
          val: "BOS",
          img: "Quiz_images__Q8-Pennant-1-2.jpg" 
        },
        {
          text: "Get a tattoo",
          val: "LON",
          img: "Quiz_images__Q8-Get-Tattoo-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Follow fashion",
          val: "BOS",
          img: "Quiz_images__Q9-Follow-fastion-Stream-1-2.jpg" 
        },
        {
          text: "Set the trends",
          val: "LON",
          img: "Quiz_images__Q9-set-trends-Stream-1-2.jpg"
        }
      ],
    ]
  },
  {
    stream: 2,
    questions: [
      [
        {
          text: "Bright lights",
          val: "DUB",
          img: "Quiz_images__Q3-Bright-lights-Stream-1-2.jpg"
        },
        {
          text: "Chilled nights",
          val: "SF",
          img: "Quiz_images__Q3-chilled-out-night-Stream-1-2.jpg"
        },
      ],
      [
        {
          text: "Join the race",
          val: "N",
          img: "Quiz_images__Q4-Join-the-race-Stream-1-2.jpg" 
        },
        {
          text: "Go at your own pace",
          val: "N",
          img: "Quiz_images__Q4-Own-pace-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Double espresso ",
          val: "N",
          img: "Quiz_images__Q5-Espresso-1-2.jpg" 
        },
        {
          text: "Pot of chai",
          val: "N",
          img: "Quiz_images__Q5-chai-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "Team sports",
          val: "N",
          img: "Quiz_images__Q6-Team-sport-1-2.jpg" 
        },
        {
          text: "E-sports",
          val: "N",
          img: "Quiz_images__Q6-Esport-Stream-1-2.jpg"
        }
      ],
      [
        {
          text: "High mileage",
          val: "DUB,SF",
          img: "Quiz_images__Q7-B-High-mileage-Stream-2.jpg" 
        },
        {
          text: "Keep it local",
          val: "N",
          img: "Quiz_images__Q7-B-Keep-it-local-Stream-2.jpg"
        }
      ],
      [
        {
          text: "365 days of sunshine",
          val: "DUB",
          img: "Quiz_images__Q8-B-365-days-summer-Stream-2.jpg" 
        },
        {
          text: "All four seasons",
          val: "SF",
          img: "Quiz_images__Q8-B-All-four-seasons-Stream-2.jpg"
        }
      ],
      [
        {
          text: "Grassroots activism",
          val: "SF",
          img: "Quiz_images__Q9-B-Grassroots-Stream-2_Q9-B-Grassroots-Stream-2.jpg" 
        },
        {
          text: "National campaign",
          val: "DUB",
          img: "Quiz_images__Q9-B-National-Stream-2.jpg"
        }
      ],
    ]
  }
]

var personalityResults = [
  {
    val: "LON",
    title: "London",
    appText: "Historic, cutting-edge, corporate, unexpected, your relationship with London will be anything but dull. This sprawling city is big on interests and even bigger on business—whatever your discipline, you’ll be sure to find something special in its reach. ",
    text: "My heart is in... London! Check out all of Hult's global campuses at hult.edu/en/locations",
    url: "https://www.hult.edu/en/locations/"
  },
  {
    val: "BOS",
    title: "Boston",
    appText: "Cerebral connections are made in America’s academic heartland. Bring your ambition to this beautiful city, where history, nature, and thriving startup scene combine to create the most dazzling of futures.",
    text: "My heart is in... Boston! Check out all of Hult's global campuses at hult.edu/en/locations",
    url: "https://www.hult.edu/en/locations/"
  },
  {
    val: "DUB",
    title: "Dubai",
    appText: "Turn up the heat! Nothing will stand in your way once united with Dubai’s fast-paced, go-getter culture. There’ll be no looking back: modernity reigns in this Middle Eastern tech hub, and the connections you make will ensure you have a global perspective for life.",
    text: "My heart is in... Dubai! Check out all of Hult's global campuses at hult.edu/en/locations",
    url: "https://www.hult.edu/en/locations/"
  },
  {
    val: "SF",
    title: "San Francisco",
    appText: "From bohemian roots to the most innovative of futures: welcome to the West Coast. Don’t let San Francisco’s casual appearance fool you—this is where go-getters make their mark. Take a moment to soak in the chilled waterfront vibes before its entrepreneurial spirit captures your heart.",
    text: "My heart is in... San Francisco! Check out all of Hult's global campuses at hult.edu/en/locations",
    url: "https://www.hult.edu/en/locations/"
  },
  {
    val: "ONLINE",
    title: "Online",
    appText: "No single campus destination could define your style. With the world at your fingertips, you’ll be bringing work, play, and study together in online harmony. Of course, our global network is waiting whenever you want to experience it—your degree, your rules.",
    text: "My heart is... Online! Check out all of Hult's global programs at hult.edu",
    url: "https://www.hult.edu/"
  },
]

export {
  personalityQuiz, personalityResults
}